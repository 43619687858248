import { Icon } from "@iconify/react";
import { Button } from "../../components/Base/Button";
import { Input } from "../../components/Base/Input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { InputValidationError } from "../../components/InputValidationError";
import {
  continueWithApple,
  loginFirebase,
  signInWithGoogleAction,
} from "../../features/auth/auth";
import { useAppDispatch } from "../../store/hooks";
import axios from "axios";
import { activateBusiness } from "../../features/business/business";

const Login = () => {
  const navigate = useNavigate();

  const handleGoogle = async () => {
    try {
      const response: any = await dispatch(signInWithGoogleAction());
      if (response.error) return;
      getBusiness();
    } catch (error) {
      console.log("error occured");
      console.error(error);
    }
  };

  const dispatch = useAppDispatch();
  const schema = yup.object().shape({
    loginEmail: yup
      .string()
      .required("Please enter your email address")
      .email("Please enter a valid email address"),
    loginPassword: yup
      .string()
      .required("Please enter your password")
      .min(6, "Passwords must be 6 or more characters"),
  });
  const makeActiveBusiness = async (payload: string | number) => {
    try {
      await dispatch(activateBusiness(payload));
      navigate("/dashboard/home");
    } catch (error) {
      console.error(error);
    }
  };
  const getBusiness = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/me/business`);
      if (!data.length) {
        // no active business and no list of businesses
        navigate("/auth/start");
      } else if (data.length > 1) {
        // has multiple businesses
        navigate("/auth/start/businesses", { state: { business: data } });
      } else if (data.length === 1) {
        // has only one business
        await makeActiveBusiness(data[0].businessId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleApple = async () => {
    try {
      const response: any = await dispatch(continueWithApple());
      if (response.error) return;
      getBusiness();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="relative overflow-scroll bg-white rounded-md w-full sm:w-[340px] sm:p-2">
        <Formik
          initialValues={{
            loginEmail: "",
            loginPassword: "",
          }}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            (async () => {
              try {
                const response: any = await dispatch(loginFirebase(data));
                setSubmitting(false);
                if (response.error) return;
                getBusiness();
              } catch (e) {
                console.log(e);
              }
            })();
          }}
          validationSchema={schema}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="p-5 space-y-5 font-roboto">
              <div className="flex flex-col items-center space-y-5">
                <Button auth="apple-button" onClick={() => handleApple()}>
                  <Icon icon="bi:apple" color="white" width={13} />
                  <span className="ml-3"> Continue with Apple</span>
                </Button>
                <Button
                  auth="google-button"
                  onClick={async () => await handleGoogle()}
                >
                  <Icon icon="flat-color-icons:google" width={13} />
                  <span className="ml-3"> Continue with Google</span>
                </Button>

                <h2 className="font-bold uppercase">OR</h2>
              </div>

              <div>
                <Field
                  name="loginEmail"
                  type="email"
                  as={Input}
                  label="Email"
                  placeholder="johndoe@gmail.com"
                />

                {errors.loginEmail && touched.loginEmail ? (
                  <InputValidationError text={errors.loginEmail} />
                ) : null}
              </div>

              <div>
                <Field
                  name="loginPassword"
                  type="Password"
                  as={Input}
                  label="Password"
                  placeholder="********"
                />

                {errors.loginPassword && touched.loginPassword ? (
                  <InputValidationError text={errors.loginPassword} />
                ) : null}
              </div>

              <div className="flex justify-end">
                <Link className="text-sm" to="/auth/forgotpassword">
                  Forgot Password?
                </Link>
              </div>

              <div className="flex justify-center">
                <Button
                  variant="bg-primary"
                  type="submit"
                  disabled={isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-8">
        <div className="flex justify-center">
          <p className="text-xs text-center text-white sm:w-7/12">
            Get your business more visibility. Create a
            <Link
              to="/auth/registeruser"
              className="px-1 text-blue-500 cursor-pointer"
            >
              merchant
            </Link>
            or
            <Link
              to="/auth/registeruser"
              className="px-1 text-center text-blue-500 cursor-pointer"
            >
              freelancer
            </Link>
            account.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
