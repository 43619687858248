import axios from "axios";
import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/Base/PageHeader";
import ImagePlaceholder from "../assets/images/emptyGallery.svg";

import ImageComponent from "../components/ImageComponent";
import NewEmptyState from "../components/NewEmptyState";
import SkeletonImage from "../components/SkeletonImage";
import useFileUpload from "../hooks/useFileUpload";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectGallery, SET_GALLERY } from "../features/pages/pages";

const Gallery = () => {
  const inputFileRef = React.useRef<any>(null);
  const { submitFile } = useFileUpload();
  const [submittingFile, setSubmittingFile] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const dispatch = useAppDispatch();
  const stateGallery = useAppSelector(selectGallery);
  const getAllImages = async () => {
    try {
      !stateGallery.length && setFetch(true);
      const { data } = await axios.get(`/api/gallery`);
      dispatch(SET_GALLERY(data.data));
    } catch (e) {
      console.error(e);
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    getAllImages();
  }, [refreshPage]);

  const onChange = async (event: any) => {
    try {
      setSubmittingFile(true);
      const file = event.target.files[0];
      const imageUrl = await submitFile(file);
      if (imageUrl) {
        sendFile(imageUrl);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSubmittingFile(false);
    }
  };
  const sendFile = async (image: string) => {
    try {
      await axios.post(`/api/gallery`, {
        file: image,
      });
      setSubmittingFile(false);
      setRefreshPage((previous) => !previous);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };

  return (
    <div className="h-screen">
      <PageHeader
        text="Gallery"
        buttontext="ADD PHOTO"
        buttonevent={() => {
          inputFileRef.current.click();
        }}
        isSubmitting={submittingFile}
      />

      {fetch ? (
        <SkeletonImage />
      ) : !stateGallery.length ? (
        <NewEmptyState
          text=" You dont have any photos"
          buttonText="Upload a photo"
          handleClick={() => {
            inputFileRef.current.click();
          }}
          image={ImagePlaceholder}
        />
      ) : (
        <div className="grid grid-cols-3 gap-5 my-5 mr-6">
          {stateGallery.map((content: any, index: number) => (
            <ImageComponent
              preview={content}
              key={index}
              refresh={() => setRefreshPage((previous) => !previous)}
            />
          ))}
        </div>
      )}

      <input
        type="file"
        name="myImage"
        multiple
        hidden
        ref={inputFileRef}
        onChange={(event) => {
          onChange(event);
        }}
      />
    </div>
  );
};

export default Gallery;
