import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import { appointmentsType } from "../../interfaces/appointments";
import { reviewType } from "../../interfaces/review";
import {
  IHomeSummaryAttributes,
  IStaffAttributes,
  serviceInfo,
} from "../../interfaces/pages";
import { scheduleType } from "../../interfaces/schedule";
import { galleryType } from "../../interfaces/gallery";
import { faqType } from "../../interfaces/faq";
import { ICRMClient, ICRMOverview } from "../../interfaces/crm";

// Define the initial state of the application
interface pagesState {
  appointments: appointmentsType[];
  dashboard: {
    reviews: reviewType[];
    summary: IHomeSummaryAttributes;
  };
  services: serviceInfo[];
  schedules: scheduleType[];
  staffs: IStaffAttributes[];
  reviews: reviewType[];
  gallery: galleryType[];
  FAQ: faqType[];
  CRM: {
    overview: ICRMOverview;
    clients: ICRMClient[];
  };
}
const initialState: pagesState = {
  appointments: [],
  dashboard: {
    reviews: [],
    summary: {
      mostBooked: "",
      rating: "",
      totalRevenue: "",
    },
  },
  services: [],
  schedules: [],
  staffs: [],
  reviews: [],
  gallery: [],
  FAQ: [],
  CRM: {
    overview: {
      totalClients: 0,
      newClients: 0,
      returningClients: 0,
    },
    clients: [],
  },
};

export const pageSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    SET_APPOINTMENTS_DATA: (
      state,
      action: PayloadAction<appointmentsType[]>
    ) => {
      const newData = action.payload.map((data) => {
        const date = new Date(data.date);
        date.setDate(date.getDate() + 1);
        return {
          ...data,
          date: date.toISOString(),
        };
      });
      state.appointments = newData;
    },
    EMPTY_ALL_PAGES: (state) => {
      return initialState;
    },
    SET_DASHBOARD_REVIEWS: (state, action: PayloadAction<reviewType[]>) => {
      state.dashboard.reviews = [...action.payload];
    },
    SET_DASHBOARD_SUMMARY: (
      state,
      action: PayloadAction<IHomeSummaryAttributes>
    ) => {
      state.dashboard.summary = action.payload;
    },
    SET_SERVICES: (state, action: PayloadAction<serviceInfo[]>) => {
      state.services = [...action.payload];
    },
    SET_SCHEDULES: (state, action: PayloadAction<scheduleType[]>) => {
      state.schedules = [...action.payload];
    },
    SET_STAFFS: (state, action: PayloadAction<IStaffAttributes[]>) => {
      state.staffs = [...action.payload];
    },
    SET_REVIEWS: (state, action: PayloadAction<reviewType[]>) => {
      state.reviews = [...action.payload];
    },
    SET_GALLERY: (state, action: PayloadAction<galleryType[]>) => {
      state.gallery = action.payload;
    },
    SET_FAQ: (state, action: PayloadAction<faqType[]>) => {
      state.FAQ = action.payload;
    },
    SET_CRM_OVERVIEW: (state, action: PayloadAction<ICRMOverview>) => {
      state.CRM.overview = action.payload;
    },
    SET_CRM_CLIENTS: (state, action: PayloadAction<ICRMClient[]>) => {
      state.CRM.clients = action.payload;
    },
  },
});

export const {
  SET_APPOINTMENTS_DATA,
  SET_DASHBOARD_REVIEWS,
  SET_DASHBOARD_SUMMARY,
  EMPTY_ALL_PAGES,
  SET_SERVICES,
  SET_SCHEDULES,
  SET_STAFFS,
  SET_REVIEWS,
  SET_GALLERY,
  SET_FAQ,
  SET_CRM_OVERVIEW,
  SET_CRM_CLIENTS,
} = pageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAppointments = (state: RootState) =>
  state.pages.appointments;
export const selectDashboard = (state: RootState) => state.pages.dashboard;
export const selectServices = (state: RootState) => state.pages.services;
export const selectSchedules = (state: RootState) => state.pages.schedules;
export const selectStaffs = (state: RootState) => state.pages.staffs;
export const selectReviews = (state: RootState) => state.pages.reviews;
export const selectGallery = (state: RootState) => state.pages.gallery;
export const selectFAQ = (state: RootState) => state.pages.FAQ;
export const selectCRM = (state: RootState) => state.pages.CRM;
export default pageSlice.reducer;
