import axios from "axios";
import { Button } from "../components/Base/Button";

const handleCompletedTour = async () => {
  const localTour = localStorage.getItem("varroe-tour");
  try {
    localTour &&
      (await axios.patch(`/api/business/hint/${JSON.parse(localTour).id}`));
  } catch (e) {
    console.error(e);
  }
};
const steps = [
  {
    selector: ".first-step",
    content: ({ setCurrentStep, setIsOpen, currentStep }: any) => (
      <div className="relative">
        <div className="absolute -left-[90px] top-[30%] ">
          <div className="bg-white h-[10px] w-[10px] rounded-full relative bottom-[0.35rem]"></div>
          <div className=" text-white rotate-[20deg]  w-[70px] border-b-2 border-white"></div>
        </div>
        <p className="text-xl interFamily font-semibold">
          Create and manage your business services
        </p>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex">
            {steps.map((each, index) => (
              <span
                key={index}
                className={
                  "w-[5px] h-[5px] rounded-full mr-2 " +
                  (index === currentStep ? "bg-black" : "bg-gray-400")
                }
              ></span>
            ))}
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => {
                handleCompletedTour();
                setIsOpen(false);
              }}
              auth="hover:opacity-70 text-white text-[10px] bg-blue-700 font-bold uppercase py-1"
            >
              Skip
            </Button>

            <Button
              onClick={() => setCurrentStep(currentStep + 1)}
              auth="hover:opacity-70 text-white text-[10px] bg-main font-bold uppercase py-0"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    ),
  },
  {
    selector: ".second-step",
    content: ({ setCurrentStep, currentStep }: any) => (
      <div className="relative">
        <div className="absolute -left-[90px] top-[30%] ">
          <div className="bg-white h-[10px] w-[10px] rounded-full relative bottom-[0.35rem]"></div>
          <div className=" text-white rotate-[20deg]  w-[70px] border-b-2 border-white"></div>
        </div>
        <p className="text-xl interFamily font-semibold">
          Take back your time. Manange the schedule for you and your staff
        </p>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex">
            {steps.map((each, index) => (
              <span
                key={index}
                className={
                  "w-[5px] h-[5px] rounded-full mr-2 " +
                  (index === currentStep ? "bg-black" : "bg-gray-400")
                }
              ></span>
            ))}
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => setCurrentStep(currentStep + 1)}
              auth="hover:opacity-70 text-white text-[10px] bg-main font-bold uppercase py-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    ),
  },
  {
    selector: ".third-step",

    content: ({ setCurrentStep, currentStep }: any) => (
      <div className="relative">
        <div className="absolute -left-[90px] top-[30%] ">
          <div className="bg-white h-[10px] w-[10px] rounded-full relative bottom-[0.35rem]"></div>
          <div className=" text-white rotate-[20deg]  w-[70px] border-b-2 border-white"></div>
        </div>
        <p className="text-xl interFamily font-semibold">
          Invite your business staff and assign them to your services
        </p>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex">
            {steps.map((each, index) => (
              <span
                key={index}
                className={
                  "w-[5px] h-[5px] rounded-full mr-2 " +
                  (index === currentStep ? "bg-black" : "bg-gray-400")
                }
              ></span>
            ))}
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => setCurrentStep(currentStep + 1)}
              auth="hover:opacity-70 text-white text-[10px] bg-main font-bold uppercase py-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    ),
  },
  {
    selector: ".fourth-step",

    content: ({ setCurrentStep, currentStep }: any) => (
      <div className="relative">
        <div className="absolute -left-[90px] top-[30%] ">
          <div className="bg-white h-[10px] w-[10px] rounded-full relative bottom-[0.35rem]"></div>
          <div className=" text-white rotate-[20deg]  w-[70px] border-b-2 border-white"></div>
        </div>
        <p className="text-xl interFamily font-semibold">
          View and keep track of all your appointments from your clients
        </p>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex">
            {steps.map((each, index) => (
              <span
                key={index}
                className={
                  "w-[5px] h-[5px] rounded-full mr-2 " +
                  (index === currentStep ? "bg-black" : "bg-gray-400")
                }
              ></span>
            ))}
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => setCurrentStep(currentStep + 1)}
              auth="hover:opacity-70 text-white text-[10px] bg-main font-bold uppercase py-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    ),
  },
  {
    selector: ".fifth-step",

    content: ({ setCurrentStep, setIsOpen, currentStep }: any) => (
      <div className="relative">
        <div className="absolute -left-[90px] top-[30%] ">
          <div className="bg-white h-[10px] w-[10px] rounded-full relative bottom-[0.35rem]"></div>
          <div className=" text-white rotate-[20deg]  w-[70px] border-b-2 border-white"></div>
        </div>
        <p className="text-xl interFamily font-semibold">
          Manage your business details, customisation info and subscription
        </p>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex">
            {steps.map((each, index) => (
              <span
                key={index}
                className={
                  "w-[5px] h-[5px] rounded-full mr-2 " +
                  (index === currentStep ? "bg-black" : "bg-gray-400")
                }
              ></span>
            ))}
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => {
                handleCompletedTour();
                setIsOpen(false);
              }}
              auth="hover:opacity-70 text-white text-[10px] bg-main font-bold uppercase py-1"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    ),
  },

  // ...
];
export default steps;
