import { Icon } from '@iconify/react';
import axios from 'axios';
import { useRef, useState } from 'react';
import useFileUpload from '../hooks/useFileUpload';
import Spinner from './Base/Spinner';
import DeleteModal from './Modals/DeleteModal';
interface Props {
  preview?: any;
  refresh?: any;
}

const ImageComponent = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);
  const inputFileRef = useRef<any>(null);
  const { submitFile } = useFileUpload();
  const [submittingFile, setSubmittingFile] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const deleteImage = async () => {
    setDeletingImage(true);
    try {
      await axios.delete(`/api/gallery/${props.preview.id}`);
      setDeletingImage(false);
      setModalOpen(false);
      props.refresh(true);
    } catch (e) {
      console.error(e);
    }
  };
  const onChange = async (event: any) => {
    try {
      setSubmittingFile(true);
      const file = event.target.files[0];
      const imageUrl = await submitFile(file);
      if (imageUrl) {
        updateFile(imageUrl);
      }
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };
  const updateFile = async (image: string) => {
    try {
      await axios.put(`/api/gallery/${props.preview.id}`, {
        file: image,
      });
      setSubmittingFile(false);
      props.refresh(true);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };
  return (
    <div className="col-span-2 md:col-span-1">
      <div className="relative h-44">
        <img
          src={props.preview.file}
          alt="img"
          className="object-cover w-full h-full rounded-xl"
        />

        <div className="absolute top-5 right-4">
          <div className="flex space-x-2">
            <div
              className="px-2 py-1 space-x-1 bg-white rounded-sm cursor-pointer"
              onClick={() => {
                inputFileRef.current.click();
              }}
            >
              <span className="text-black text-[10px] flex items-center font-semibold">
                {submittingFile ? (
                  <>
                    <Spinner />
                    <p className=""> Updating...</p>
                  </>
                ) : (
                  <>
                    <Icon icon="ant-design:upload-outlined" />
                    <p className="pl-1"> Update</p>
                  </>
                )}
              </span>
            </div>
            <div
              className="flex items-center p-1 bg-white rounded-sm cursor-pointer"
              onClick={toggleModal}
            >
              <span className="text-danger text-[10px]">
                <Icon icon="charm:bin" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <input
        type="file"
        name="myImage"
        multiple
        hidden
        ref={inputFileRef}
        onChange={(event) => {
          onChange(event);
        }}
      />

      <DeleteModal
        modalOpen={modalOpen}
        closeModal={toggleModal}
        type="Photo"
        image={props.preview.file}
        size="small"
        delete={deleteImage}
        isSubmitting={deletingImage}
      />
    </div>
  );
};

export default ImageComponent;
